/* src/pages/Home.css */
.div-main{
  padding: 0;
  margin: 0;
}
.header-container {
  display: flex;
  justify-content: space-between; /* Text on the left, logo on the right */
  align-items: center; /* Vertically center content */
  padding: 5px;
  background-color: #1700b7; /* Optional: Light background color */
  color: white;
}

.text-container {
  display: flex;
  flex-direction: column; /* Stack the h1 and h3 vertically */
  margin: 1rem;
}

.text-container h1 {
  font-size: 2rem;
  margin: 0;
}

.text-container h3 {
  margin-top: 5px; /* Adjust spacing between h1 and h3 */
  margin-bottom: 5px;
}
.headp{
  line-height: 0px;

}
.header-logo-text{
  text-align: center;
  margin-right: 1rem;
  margin-top: 1rem;
}
.organization-logo {
  height: 80px; /* Adjust logo size */
  width: auto;  
}

/*********about**********/

.about {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 20px;
  padding-left: 10rem;
  padding-right: 10rem;
}
.box{
  background-color: #1700b7;
  text-align: center;
  color: white;
  overflow: scroll;
  max-width: 15rem;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  line-height: 25px;
  height: 20rem;
}
.box p{
  text-align: justify;
  margin: 20px;
}
.boximg{
  height: 7rem;
  text-align: center;
  margin-top: 20px;
}
.boximg3{
  height: 5rem;
  text-align: center;
  margin-top: 40px;
}
/*********distribution*********/
.distribution {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 3rem;
  gap: 2rem;
  padding-left: 7rem;
  padding-right: 7rem;
}

.distribution-container {
  position: relative;
  background-image: url('../assets/sweater.jpg');
  height: 28rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden; /* Prevents scrolling in the main container */
}

.distribution-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 255, 0); /* Initially transparent */
  transition: background-color 1s ease;
  z-index: 1; /* Places overlay above the background but below text */
}

.distribution-container:hover::after {
  background-color: rgba(0, 0, 255, 0.7); /* Blue color with 70% opacity */
}

.hover-caption {
  color: white;
  overflow-y: auto; 
  height: 73%; 
  position: relative;
  z-index: 2; 
}

.hover-caption p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  opacity: 0;
  transition: opacity 1s ease;
  text-align: center;
  height: 100%;
  text-align: justify;
}

.hover-caption:hover p {
  opacity: 1;
  color: white;
}

.distribution-container2 {
  position: relative;
  background-image: url('../assets/blanket.jpg');
  height: 28rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.distribution-container2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 255, 0); /* Initially transparent */
  transition: background-color 1s ease;
  z-index: 1; /* Places overlay above the background but below text */
}

.distribution-container2:hover::after {
  background-color: rgba(0, 0, 255, 0.7); /* Blue color with 70% opacity */
}

.dis-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px;
  background-color: #1700b7;
  opacity: 0.7;
  text-align: center;
  overflow: hidden; /* Keeps dis-caption fixed */
  z-index: 2; /* Ensures it stays above the overlay */
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  opacity: 1;
  color: white;
  z-index: 3;
}

/*******cause*********/
.heading{
  text-align: center;
  margin-top: 3rem;  
  font-size: 2rem;
  color: rgba(25, 0, 123, 0.938);
  text-decoration: underline;
}
.cause{
  margin-top: 2rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  padding-left: 10rem;
  padding-right: 10rem;
  width: inherit;
  color: rgba(25, 0, 123, 0.938);
}
.causebox{
  border: 2px solid #1700b7;
  text-align: center;
}
.causebox p{
  text-align: justify;
  padding: 20px;
  color: rgba(25, 0, 123, 0.938);
  font-weight: bold;
}
.causepicture{
  height: 11rem;
  width: 100%;
}
/**********image slide************/
.image-slide{
  width: 60%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin-left: 6.5rem;
  gap: 3rem;
  margin-bottom: 3rem;
}
.sliderimg{
  height: 8rem;
}
/**********Footer*********/
.footer{
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  border: 1px solid #1700b7;
  background: #1700b7;
  text-align: center;
  color: aliceblue;
  padding: 20px;
  font-weight: bolder;
}
.address{

}
.hr{
  width: 10rem;
  color: black;
}
.contact a{
  text-decoration: none;
  color:white;
}
.cp{
  text-align: center;
  background-color: #0f0263;
  margin: 0;
  border: 1px solid #0f0263;
  color: white;
}
.socialgrid{
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-left: 13rem;
  margin-right: 10rem;
}
.socialgrid img{
  height: 2rem;
}
/**********media query***********/

/* Medium devices (tablets, 768px and down) */
@media (max-width: 768px) {
  .header-container {
    padding: 0px;
    margin-bottom: 0;
  }
  
  .text-container h1 {
    font-size: 1.75rem;
  }
  .headp{
    line-height: 1.5rem;
  
  }
  .about {
    margin-top: 0;
    padding-left: 3px;
    padding-right: 3px;
    display: block;
  }
  .box{
    margin-top: 20px;
    background-color: #1700b7;
    text-align: center;
    color: white;
    overflow: scroll;
    max-width: 25rem;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    line-height: 25px;
    height: 20rem;
  }
  .distribution {
    grid-template-columns: 1fr;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .cause {
    grid-template-columns: auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .image-slide {
    grid-template-columns: repeat(2, auto);
    margin-left: 2rem;
  }

  .footer {
    grid-template-columns: 1fr;
    padding: 15px;
  }

  .socialgrid {
    grid-template-columns: repeat(4, auto);
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .header-container {
    padding: 20px;
  }

  .about {
    grid-template-columns: auto auto auto auto;
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .distribution {
    grid-template-columns: 1fr 1fr;
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .cause {
    grid-template-columns: auto auto auto auto;
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .image-slide {
    grid-template-columns: repeat(7, auto);
    width: 60%;
    margin-left: 6.5rem;
  }

  .footer {
    grid-template-columns: 1fr 1fr;
    padding: 20px;
  }

  .socialgrid {
    grid-template-columns: repeat(4, auto);
    margin-left: 13rem;
    margin-right: 10rem;
  }
}
