/* src/components/Carousel.css */
.carousel {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    margin-top: 10px;
  }
  
  .carousel-inner {
    position: relative;
    height: 600px; /* Ensures consistent height for the carousel */
  }
  
  .carousel-image {
    position: absolute; /* Position all images on top of each other */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0; /* Start images as hidden */
    transition: opacity 2s ease-in-out; /* Smooth fade transition for images */
  }
  
  .carousel-image.active {
    opacity: 1; /* Only active image will be visible */
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    padding: 1rem 2rem; /* Larger padding for better button visibility */
    font-size: 2rem; /* Larger control button */
    cursor: pointer;
    z-index: 10; /* Ensure control buttons are always on top */
    border-radius: 50%; /* Make control buttons round */
    transition: background-color 0.3s ease; /* Smooth hover effect */
  }
  
  .carousel-control:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker background on hover */
  }
  
  .prev {
    left: 20px; /* Move control button slightly further away from the edge */
  }
  
  .next {
    right: 20px; /* Same as above for the next button */
  }
  
  @media (max-width: 768px) {
    /* Responsive adjustments */
    .carousel-image {
      height: 400px; /* Adjust height for smaller screens */
    }
  
    .carousel-control {
      padding: 0.75rem 1.5rem;
      font-size: 1.5rem;
    }
  }
  
  @media (max-width: 480px) {
    /* Further adjustments for very small screens */
    .carousel-image {
      height: 300px; /* Even smaller height for mobile devices */
    }
  
    .carousel-control {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
    }
  }
  